import React from "react";
import Image from "./image";

const Training = ({
  cover,
  title,
  description,
  agenda,
  buy,
  homepage,
  price,
  attendees,
  mentorsLabel,
  mentors,
  tags,
}) => {
  return (
    <>
      <article className="h-full group rounded-xl backface-hidden transform-gpu overflow-hidden shadow-2xl border-border border flex flex-col">
        <a href={homepage}>
          <div className="aspect-w-16 aspect-h-9 overflow-hidden">
          <div className="absolute  transition-opacity opacity-0  duration-500  group-hover:opacity-30 bg-orange-500 w-full h-full z-10"></div>
          <div>
            <Image
              alt={title}
              className="transition-transform duration-500 transform group-hover:scale-105"
              src={cover}
              objectFit="cover"
            />
            </div>
          </div>
        </a>
        <div className="px-5 py-5 border-b border-border">
          <h3 className="text-base lg:text-xl font-semibold mb-1 transition-colors duration-500 group-hover:text-orange-500">
            <a href={homepage}>{title}</a>
          </h3>
          <ul className="mb-3 flex flex-wrap gap-2">
            {tags.map((tag, index) => (
              <li key={index} className="text-xs whitespace-nowrap opacity-70 py-1 px-1 border-border border ">
                {tag}
              </li>
            ))}
          </ul>
          <div className="min-h-19">
            <p className="text-gray-700 text-xs leading-5 mb-3 min-h-10">
              {description}
            </p>
            {agenda ? (
              <a
                href={agenda}
                className="inline-flex hover:text-orange-500 items-center font-semibold"
                target="_blank"
                rel="noreferrer"
              >
                Zobacz agendę
                <svg
                  className="ml-2"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.78166 5.33361L5.20566 1.75761L6.14833 0.814941L11.3337 6.00027L6.14833 11.1856L5.20566 10.2429L8.78166 6.66694H0.666992V5.33361H8.78166Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            ) : null}
          </div>
        </div>
        <section className="py-5 px-5">
          <h4 className="opacity-50 text-xs mb-2">{mentorsLabel}</h4>
          <ul className="flex gap-2 flex-wrap xl:flex-nowrap list-none mx-0">
            {mentors.map((mentor, index) => (
              <li key={index} className="list-none mx-0 px-0 flex items-center">
                <div className="backface-hidden transform-gpu rounded-full w-10 h-10 flex-shrink-0 flex-grow-0 mr-2 overflow-hidden">
                <Image
                  alt=""
                  className=""
                  src={mentor.picture}
                  objectFit="cover"
                />
                </div>
                <span className="text-xs font-semibold">{mentor.name}</span>
              </li>
            ))}
          </ul>
        </section>
        <footer className="py-5 px-5 flex items-center  mt-auto border-t border-border justify-between">
          <p className="text-xs font-semibold">{attendees}</p>
          { price ? (
            <p className="text-lg font-semibold ml-auto">{price}
            <a className="btn-orange-alternative"
                  rel="noreferrer" href={buy} target="_blank">
              Dołącz »
            </a></p>
          )
          : (
            <a className="btn-orange-alternative" rel="noreferrer" href={homepage} target="_blank">
            Zapisz się na mailing »
          </a>
          )
          }
        </footer>
      </article>
    </>
  );
};

export default Training;
